import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionPreferenceMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.preference ? publicData.preference : [];
  var selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));
  if (typeof window !== 'undefined') {
    var language = window.localStorage.getItem('lang');
    if (language != 'EN') {
      selectedConfigOptions = selectedConfigOptions.map(e => {
        return {
          ...e,
          label: e.polishLabel,
        };
      });
    }
  }
  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.preferenceTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.preference"
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions.length > 5}
      />
    </div>
  );
};

export default SectionPreferenceMaybe;
